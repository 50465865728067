import React from 'react'
import { Link, graphql } from 'gatsby'
import { Layout, SEO } from '@components'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const socialImage = data.file.childImageSharp.resize
  console.log(socialImage)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="A blog by Brad Zickafoose" image={socialImage} />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article className="push-large--bottom" key={node.fields.slug}>
            <header className="txt-neutral">
              <small>
                {node.frontmatter.date} {` • ${node.timeToRead} min read`}
              </small>
            </header>
            <h3 style={{ marginTop: `0` }}>
              <Link to={node.fields.slug}>{title}</Link>
            </h3>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 150)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
    file(relativePath: { eq: "images/social-card.png" }) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`
